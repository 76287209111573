$logobox: 42vmin;
$carousellogoratio: (3.0 / 4);
$logolines: 3;
$overshoot: 0.70;
$leftright: 26vmin;
$spacing: 10vmin;
$focussize: 1.6;
$logopadding: 1.7vmin;

$white: #ffffff;
$color_dark: #002222;
$black: #000000;
$color_accent: #220000;

$mobilemenupeek: 15vw;

//@import "./includes/bootstrap";

@import "../node_modules/tail.select/css/bootstrap3/tail.select-default.min";
@import "../node_modules/glider-js/glider";

.gliders {
  > .glider-prev,> .glider-next {
    text-decoration: none;
    color: $white;
  }
  > .glider-prev {
    left: $leftright;
  }
  > .glider-next {
    right: $leftright;
  }
  > div {
    left: (-$overshoot/2 * 100%);
    width: (100% * (1 + $overshoot));
    &.dots {
      width: inherit;
    }
    > div {
      > a {
       	transition: opacity 400ms, scale 400ms, ease-in-out 400ms;
	background-color: $white;
        border: 1px solid $color_dark;
        margin: $spacing;
        margin-top: 4vmin;
        margin-bottom: 4vmin;
        width: $logobox;
        text-decoration: none;
        color: $black;
        padding: $logopadding;

        > img {
          object-fit: contain;
          width: 100%;
          height: ($logobox * $carousellogoratio);
        }
        > div {

	  > h2 {
	    font-weight: bold;
	  }
          > p {
            overflow: hidden;
            text-overflow: ellipsis;
            display: -webkit-box;
            -webkit-box-orient: vertical;
            -webkit-line-clamp: $logolines;
            line-height: 1.2;
            height: calc(1.2em * #{$logolines});
          }
        }
        &.left-1,&.right-1 {
	  transform: scale(0.9);
          opacity: 0.6;
          filter: blur(3px);
          border: none;
        }
      }
    }
  }
}

.tail-select .select-dropdown {
  position: inherit;
}

.loginspacer {
  min-height: 35vh;

  > div > div {
    min-height: 4.5em;
  }
  .label {
    padding: 1em;
    text-align: right;
  }
  .text {
    padding: 1em;
    padding-left: 2em;
  }

  input {
    margin: 1em;
    padding: 5px;
    padding-left: 15px;

    &[type="submit"] {
      background-color: $color_accent;
      color: $white;
      transition: color 0.2s linear, background-color 0.3s linear;
      padding-right: 15px;
      text-align: left;
      min-width: 130px;
    
      &:hover {
        background-color: $white;
        color: $color_dark;
      }
    }
  }
}

a.savebutton {
  background-color: $color_accent;
  color: $white;
  
  &:hover {
    background-color: $white;
    color: $color_dark;
  }
}

.invalid {
  border: 3px solid red;
}
